
import {defineComponent} from "vue";

import List from "@/components/payment-methods/bank-accounts/List.vue";

export default defineComponent({
    name: "bank-account-list",
    components: {
        List,
    },
    setup() {
        return {

        };
    },
});
