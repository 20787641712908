
import {computed, defineComponent, ref,} from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {Actions} from "@/store/enums/StoreEnums";
import store from "@/store";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

import AddNew from "@/components/payment-methods/bank-accounts/AddNew.vue";
import Update from "@/components/payment-methods/bank-accounts/Update.vue";

export default defineComponent({
  name: "bank-accounts-lists",
  components: {
    AddNew,
    Update,
  },
  setup() {

    store.dispatch(Actions.BANKACCOUNTS_LIST, {});
    const databankAccountsList = computed(() => {
      return store.getters.bankAccountsList;
    });

    const deleteBankAccount = (ID) => {
      store.dispatch(Actions.BANKACCOUNTS_LIST, {});
      Swal.fire({
        title: 'Emin misiniz?',
        text: "Kaydi silmek işlemi geri alınamaz.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: 'Kaydı Sil',
        cancelButtonText: `Vazgeç`,
        confirmButtonColor: '#9c1d1d',
        cancelButtonColor: '#3085d6',
      }).then(function (result) {
        if (result.isConfirmed) {
          const payload1 = {
            ID: ID,
          }
          store.dispatch(Actions.BANKACCOUNTS_DELETE, payload1)
              .then(() => {
                store.dispatch(Actions.BANKACCOUNTS_LIST, {});
                Swal.fire(
                    'Silindi!',
                    'Kayit silme işlemi tamamlandı.',
                    'success'
                )
              }).catch(() => {
            Swal.fire({
              text: store.getters.getBankAccountsErrors[0],
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Tekrar dene!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });

        }
      });
    };
    const itemID = ref<number>(0);
    function setItems(ID) {
      itemID.value=ID;
    }

    return {
      databankAccountsList, deleteBankAccount, libTools, setItems, itemID,
    };
  },

});
