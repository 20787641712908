
import {computed, defineComponent, onMounted, ref,} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {ErrorMessage, Field, Form} from "vee-validate";
import * as Yup from "yup";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import currency from "@/core/data/currency";

interface IFormVals {
    BankID: string;
    Title: string;
    ShortHand: string;
    IBAN: string;
    Cur: string;
}

export default defineComponent({
    name: "bank-accounts-addnew",
    components: {
        Field,
        Form,
        ErrorMessage,
    },

    setup(props) {

        onMounted(() => {
            setCurrentPageBreadcrumbs("Banka Hesapları", ["Ödeme Yöntemleri"]);
        });

      store.dispatch(Actions.BANKS_LIST, {});
      const dataBanksList = computed(() => {
        console.log(store.getters.banksList)
        return store.getters.banksList;
      });

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const modalRef = ref<null | HTMLElement>(null);

        const formVals = ref<IFormVals>({
            BankID:"",
            Title: "",
            ShortHand: "",
            IBAN: "",
            Cur: "",
        });

        const validationSchema = Yup.object().shape({
            BankID: Yup.string().required("Banka Adı girilmemiş"),
            Title: Yup.string().required("Hesap Adı girilmemiş"),
            ShortHand: Yup.string().required("Hesap Kısa Adı girilmemiş"),
            IBAN: Yup.string().required("IBAN No seçilmemiş"),
            Cur: Yup.string().required("Kur seçilmemiş"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const payload1 = {
                BankID: formVals.value.BankID,
                Title: formVals.value.Title,
                ShortHand: formVals.value.ShortHand,
                IBAN: formVals.value.IBAN,
                Cur: formVals.value.Cur,
            }

            console.log("submit v2 "+JSON.stringify(payload1));

            store.dispatch(Actions.BANKACCOUNTS_INSERT, payload1)
                .then(() => {
                    store.dispatch(Actions.BANKACCOUNTS_LIST, {});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(modalRef.value);
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getBankAccountsErrors[0],
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Tekrar dene!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-danger",
                        },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });


        }

        return {
            formVals, validationSchema, submit,
            submitButtonRef, modalRef, currency, dataBanksList,
        };
    },

});
